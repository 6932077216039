var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('base-header',{attrs:{"title":_vm.$t('app.usuarios-sistema.name'),"link":"Dashboard"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"flex-grow-1 flex-grow-0"},[_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('app.usuarios-sistema.title'))+" ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('app.usuarios-sistema.subtitle'))+" ")])]),_c('v-col',{staticClass:"d-flex flex-row-reverse align-center flex-grow-0 flex-shrink-0"},[_c('v-btn',{attrs:{"dark":""},on:{"click":_vm.openItemAdd}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" ")],1)],1)],1)]},proxy:true}])},[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.filters.data.roles,"item-text":"name","item-value":"id","label":_vm.$t('app.roles.name'),"multiple":"","chips":"","deletable-chips":"","clearable":"","outlined":"","rounded":"","dense":""},model:{value:(_vm.filters.selected.roles),callback:function ($$v) {_vm.$set(_vm.filters.selected, "roles", $$v)},expression:"filters.selected.roles"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('app.buttons.search'),"rounded":"","outlined":"","dense":"","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.filters.selected.query),callback:function ($$v) {_vm.$set(_vm.filters.selected, "query", $$v)},expression:"filters.selected.query"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":10,"search":_vm.filters.selected.query},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return [(item.roles.length > 0)?_vm._l((item.roles),function(role,index){return _c('v-chip',{key:("role-" + index),attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(role.name)+" ")])}):_vm._e()]}},{key:"item.sucursales",fn:function(ref){
var item = ref.item;
return [(item.sucursales.length > 0)?_vm._l((item.sucursales),function(sucursal,index){return _c('v-chip',{key:("sucursal-" + index),attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(sucursal.nombre)+" ")])}):_vm._e()]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"src":item.avatar,"lazy-src":_vm.preloader,"width":"60"}})],1)]}},{key:"item.direccion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([ item.persona.calle, item.persona.colonia, item.persona.localidad.nombre, item.persona.municipio.nombre, item.persona.estado.nombre ].join(', '))+" ")]}},{key:"item.telefonos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.persona.telefonos ? item.persona.telefonos.join(', ') : '')+" ")]}},{key:"item.fecha_nacimiento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.persona.fecha_nacimiento).format('MMM Do, YYYY'))+" "),_c('br'),_c('small',[_vm._v(_vm._s(_vm.$moment(item.persona.fecha_nacimiento).fromNow(true)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openItemDetail(item)}}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openItemEdit(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openItemDelete(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]}}])})],1)],1)],1),_c('usuarios-add'),_c('usuarios-edit'),_c('usuarios-detail'),_c('usuarios-delete'),_c('jugadores-add'),_c('jugadores-add-to-team-question'),_c('jugadores-add-to-team'),_c('jugadores-add-to-team-edit'),_c('arbitros-add')],1)}
var staticRenderFns = []

export { render, staticRenderFns }